import {
  FacetFilterValue,
  FacetOptionsValue,
} from '../../../../../integrations';
import { keys } from '../../../../../utils';
import { ProductsFilter } from './types';

const filterTypeMap = {
  price: 'PRICE',
  collections: 'CATEGORY',
};

export function convertSearchFacetsToProductFilters(
  facets: FacetOptionsValue,
  selectedFilters: FacetFilterValue,
): ProductsFilter[] {
  return keys(facets)
    .map((facetName) => {
      switch (facetName) {
        case 'price':
          const value = facets[facetName]!;
          if (value.min === value.max) {
            return null;
          }
          return {
            filterType: filterTypeMap[facetName],
            field: facetName,
            name: facetName,
            values: [
              {
                id: null,
                key: value.min.toString(),
                value: value.min.toString(),
              },
              {
                id: null,
                key: value.max.toString(),
                value: value.max.toString(),
              },
            ],
          };
        case 'collections':
          const selectedCollections = selectedFilters.collections ?? [];
          const availableCollections = facets[facetName] ?? [];
          const collections = [
            ...availableCollections,
            ...selectedCollections
              .filter(
                (label) =>
                  !availableCollections.some(
                    (collection) => collection.label === label,
                  ),
              )
              .map((label) => ({ label, count: 0 })),
          ];

          return {
            filterType: filterTypeMap[facetName],
            field: 'categoryId',
            name: 'categoryId',
            values: collections.map((collection) => {
              return {
                id: collection.label,
                key: collection.label,
                value: collection.label,
              };
            }),
          };
        default:
          return null;
      }
    })
    .filter((value) => Boolean(value)) as ProductsFilter[];
}
