import { ClientSearchSDK, SearchDocumentType } from '@wix/client-search-sdk';
import { createSettingsClient } from '@wix/search-settings-client';
import type { ControllerParams } from '@wix/yoshi-flow-editor';
import { Spec } from '@wix/site-search-common';

import { IExtendedControllerParams } from './platform.types';
import { createSearchLocation } from './location';
import { reportError } from './errors';

export const extendControllerParams = async (
  params: ControllerParams,
  supportsEnrichedProductDocuments: boolean = true,
): Promise<IExtendedControllerParams> => {
  const { flowAPI, controllerConfig } = params;
  const { wixCodeApi } = controllerConfig;
  const { environment, errorMonitor, experiments, httpClient } = flowAPI;
  const { isViewer, language } = environment;
  const siteBaseUrl = wixCodeApi.location.baseUrl;

  const settingsClient = createSettingsClient({
    httpClient,
  });

  const getAppSettings = async () => {
    try {
      return isViewer
        ? await settingsClient.getPublished()
        : await settingsClient.getSaved();
    } catch (error) {
      reportError(errorMonitor, error);
      return settingsClient.getDefault();
    }
  };

  let useRawProductDocuments;
  if (supportsEnrichedProductDocuments) {
    const { isProductSlotInstalled } = await getAppSettings();
    useRawProductDocuments = isProductSlotInstalled;
  } else {
    useRawProductDocuments = false;
  }
  const { isProductSlotInstalled } = await getAppSettings();

  const searchLocation = createSearchLocation(wixCodeApi);

  const searchSDK = new ClientSearchSDK({
    httpClient,
    siteBaseUrl,
    language,
    timeout: 25000,
    excludeDocumentTypes: experiments.enabled(Spec.ProGallery)
      ? undefined
      : [SearchDocumentType.ProGallery],
    useRawProductDocuments,
  });

  return {
    ...controllerConfig,
    getAppSettings,
    searchLocation,
    searchSDK,
    flowAPI,
    isProductSlotInstalled,
  };
};
